import fetchRetry from 'fetch-retry';
import A11yDialog from 'a11y-dialog';
import {
  get as getCookie,
  set as setCookie,
  remove as removeCookie,
} from 'es-cookie';
import pkceChallenge from 'pkce-challenge';
import invariant from 'tiny-invariant';
import type {
  BBMemberships,
  BBUser,
  Service,
  ServiceLinkItem,
  TemplateVars,
} from '@server/types';
import type { CurrentUser, GrowthHubQueryParam } from '@client/types';
import { ActiveStateService } from '@client/services/active-state-service';
import { IntercomService } from '@client/services/intercom-service';
import parsePostMsg from '@client/utils/parse-post-msg';
import parseGhQp from '@client/utils/parse-gh-qp';
import generateTriggerEvent from '@client/utils/generate-trigger-event';
import createMenuItem from '@client/utils/create-menu-item';

// Assets: CSS
import apolloCss from '@xplor/apollo-core/build/style.css';
import growthHubCss from '@client/assets/css/growth-hub.css';
import growthHubModalCss from '@client/assets/css/growth-hub-modal.css';

// Assets: SVG
import growthHubSvg from '@client/assets/svgs/growth-hub.svg';
import classBtnSvg from '@client/assets/svgs/close-btn.svg';

type XplEvtNames = (typeof xplEvtNames)[number];
type XplEvts = {
  [Property in XplEvtNames]: `XplGH::${Property}`;
};
type XplEvt = XplEvts[keyof XplEvts];

const xplEvtNames = [
  'afterAccountSelected',
  'afterAuthComplete',
  'afterLogoutComplete',
] as const;
const xplEvts = xplEvtNames.reduce(
  (acc, evt) => ({ ...acc, [evt]: `XplGH::${evt}` }),
  {},
) as XplEvts;

const triggerEvent = generateTriggerEvent<XplEvt>();

const selectedAccountNameAttrName = 'selected-account-name';
const selectedAccountIdAttrName = 'selected-account-id';
const isFixedAttrName = 'is-fixed';

const generateAccountListReducer =
  (selectedAccountId: number | undefined, isSuperUser = false) =>
  (
    acc: string,
    {
      name: label,
      id,
      account_user_uuid: uuid,
    }: BBMemberships['memberships'][0],
  ) => `
  ${acc}
  <li${selectedAccountId === id ? ' class="selected"' : ''}>
    <button
      class="xpl-list-item"
      data-gh-account-option="true"
      data-gh-account-option-name="${label}"
      data-gh-account-option-uuid="${uuid}"
      data-gh-account-option-id="${id}"
    >
      ${label} ${isSuperUser ? `<small>(id: ${id})</small>` : ''}
    </button>
  </li>
`;

export default class GrowthHubElement extends HTMLElement {
  AUTH_SESSION_NAME = '__xpl-gh-auth-session';

  AUTH_CODE_VERIFIER_COOKIE_NAME = 'xpl-auth-code-verifier';

  GROWTH_HUB_QUERY_PARAM_KEY = '__xpl_gh';

  accountDeetsContainerSelector = 'account-details';

  accountPickerId = 'account-picker';

  accounts: BBMemberships['memberships'];

  baseAuthUrl = process.env.AUTH_API_URL;

  brandbotApiUrl = process.env.BRANDBOT_API_URL;

  clientId = process.env.AUTH_CLIENT_ID;

  currentAccountDisplaySelector = 'xpl-gh-current-account-display';

  ddOpenSelector = 'xpl-dropdown--open';

  dialog: A11yDialog | null = null;

  // These are id selectors
  dialogContentSelectors = {
    accountSwitcher: 'dialog__account-switcher',
    buyNow: 'dialog__buy-now',
    buyNowLink: 'dialog__buy-now-button',
  };

  dialogSearchFormSelector = 'xpl-gh-dialog-search';

  dialogSelector = 'account-selector';

  fetchRetry: ReturnType<typeof fetchRetry>;

  growthHubBaseUrl = process.env.GROWTH_HUB_BASE_URL;

  growthHubHomeUrl = window.location.origin;

  iframeClassKey = 'gh-container';

  isDevEnv = process.env.NODE_ENV === 'development';

  navItemActiveClass = 'active';

  ctaContentMap = {
    default: {
      title: `<h2 class="xpl-gh-text-title-3">We&apos;re glad to see you here!</h2>`,
      body: `
        <p>Click &lsquo;Request Activation&rsquo; to request access to this product. Access will be granted on a rolling basis. By requesting activation, you hereby agree to <a href="https://docs.brandbot.com/en/articles/8973484-early-access-and-beta-policy" target="_blank" rel="nofollow">Xplor Growth&apos;s Early Access and Beta Policy</a>. Once activated, your beta period<sup>*</sup> will begin.</p>
            <p><small>*Gamification is excluded from the beta period</small></p>
      `,
      ctaButton: `<button>Request Activation</button>`,
    },
    feedback: {
      title: `<h2 class="xpl-gh-text-title-3">We&apos;re glad to see you here!</h2>`,
      body: `
        <p>We&apos;re thrilled for you to experience this product and see the difference it can make for your studio. To learn more about how to get started, click &lsquo;Request Information&rsquo;, and a member of our Customer Success team will reach out with more details!</p>
      `,
      ctaButton: `<button>Request Information</button>`,
    },
  };

  private activeStateService: ActiveStateService;

  private intercomService: IntercomService;

  static observedAttributes = [
    selectedAccountIdAttrName,
    selectedAccountNameAttrName,
    isFixedAttrName,
  ];

  services: ServiceLinkItem[] = [];

  txMsgHref = process.env.TX_MSG_URL;

  user: CurrentUser = {} as CurrentUser;

  constructor() {
    super();

    this.activeStateService = new ActiveStateService(this.navItemActiveClass);
    this.intercomService = new IntercomService(
      process.env.INTERCOM_APP_ID ?? '',
    );
  }

  async attributeChangedCallback(
    name: string,
    oldValue: string,
    newValue: string,
  ) {
    switch (name) {
      case selectedAccountIdAttrName:
        if (oldValue !== null && oldValue !== newValue) {
          const accountName = this.attributes.getNamedItem(
            selectedAccountNameAttrName,
          )?.value;

          await this.setActiveAccount(this.shadowRoot as ShadowRoot, {
            name: accountName as string,
            id: Number(newValue),
            isSuper: false,
          });
        }
        break;
      default:
        break;
    }
  }

  async connectedCallback() {
    // Create a shadow root
    const shadow = this.attachShadow({ mode: 'open' });

    [
      [this.baseAuthUrl, 'baseAuthUrl is missing a value'],
      [this.clientId, 'clientId is missing a value'],
      [this.growthHubBaseUrl, 'growthHubBaseUrl is missing a value'],
      [this.growthHubHomeUrl, 'growthHubHomeUrl is missing a value'],
      [this.txMsgHref, 'txMsgHref is missing a value'],
    ].forEach(([variable, msg]) => invariant(variable, msg));

    this.fetchRetry = fetchRetry(window.fetch, {
      retries: 5,
      retryDelay: 1000,
      retryOn: async (attempt, _, response) => {
        if (
          (attempt < 5 &&
            response?.url.includes('/login') &&
            response?.redirected === true) ||
          (attempt < 5 && response?.status === 401)
        ) {
          await this.handleLogin(shadow, true);
          return true;
        }

        return false;
      },
    });

    await this.initGHComponent(shadow);
  }

  // For now, clicked node will no longer be used because we're
  // not ready to implement self-upgrade flows in each app yet.
  // However, i'd like to maintain the functionality for the future,
  // so its it easy to implement again. With all of that said,
  // the next line will ignore eslint's callout about an unused param.
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  displayModalContent(nextSelector: string, clickedNode: HTMLElement) {
    const modalContentNodes = Array.from(
      document.querySelectorAll<HTMLDivElement>('.xpl-gh-dialog-content-group'),
    );

    modalContentNodes.forEach((node) => {
      if (node.id === nextSelector) {
        node.removeAttribute('aria-hidden');
        return;
      }

      node.setAttribute('aria-hidden', 'true');
    });

    document.getElementById(this.dialogContentSelectors.buyNowLink);
  }

  showBuyNowModal(buttonNode: HTMLElement, shadow: ShadowRoot) {
    document
      .getElementById(this.dialogContentSelectors.buyNow)
      ?.setAttribute(
        'data-gh-service-name',
        buttonNode.dataset?.ghServiceName as string,
      );
    this.displayModalContent(this.dialogContentSelectors.buyNow, buttonNode);

    switch (buttonNode.dataset.ghServiceName?.toLowerCase()) {
      case 'feedback':
        this.setBuyNowCta('feedback');
        break;
      default:
        this.setBuyNowCta('default');
        break;
    }

    this.dialog?.show();
    const headerEle = shadow.querySelector('header');
    const growthHubEle = document.querySelector('growth-hub');

    headerEle?.classList.add('dialog--is-active');
    growthHubEle?.classList.add('dialog--is-active');

    this.dialog?.on('hide', () => {
      headerEle?.classList.remove('dialog--is-active');
      growthHubEle?.classList.remove('dialog--is-active');
    });
  }

  async initGHComponent(shadow: ShadowRoot) {
    const initialAccountName = this.attributes.getNamedItem(
      selectedAccountNameAttrName,
    );
    const initialAccountId = this.attributes.getNamedItem(
      selectedAccountIdAttrName,
    );
    const { value: isFixed = 'true' } =
      this.attributes.getNamedItem(isFixedAttrName) || {};

    document.addEventListener(xplEvts.afterAuthComplete, async () => {
      await this.setAccount(shadow, {
        name: initialAccountName?.value || '',
        id: Number(initialAccountId?.value) || null,
        isSuper: false,
      });

      this.buildAccountPreloader(shadow);

      if (this.user) {
        this.buildCurrentAccountDisplay(shadow, this.user.currentAccount);
        this.buildCurrentUserAvi(shadow, this.user.fullName);
        this.buildAccountDropdown(shadow);
        this.removeAccountPreloader(shadow);
      }

      this.buildAccountModal(
        shadow,
        this.accounts,
        this.user?.currentAccount.id,
        this.user?.isSuper,
      );
      this.handleAccountsSearch(this.accounts);

      setTimeout(() => {
        const accountDetailsContainer = shadow.getElementById(
          this.accountDeetsContainerSelector,
        );
        accountDetailsContainer?.classList.remove('overflow-hidden');
      }, 150);

      this.buildNavigation(shadow);

      const curUrl = new URL(window.location.href);
      curUrl.searchParams.delete(this.GROWTH_HUB_QUERY_PARAM_KEY);
      window.history.replaceState(null, document.title, curUrl.toString());

      this.intercomService.init(this.user);

      this.checkIfShowBuyModal(shadow);

      this.dialog?.on('hide', () => this.checkIfShowBuyModal(shadow));

      window.addEventListener<'popstate'>('popstate', () => {
        this.checkIfShowBuyModal(shadow);
      });

      document
        ?.getElementById(this.dialogContentSelectors.buyNowLink)
        ?.querySelector('button')
        ?.addEventListener<'click'>('click', (e) => {
          e.preventDefault();

          const btn = e.target as HTMLButtonElement;
          const header = document
            .getElementById(this.dialogContentSelectors.buyNow)
            ?.querySelector('.xpl-gh-dialog-header h2') as HTMLElement;
          const body = document
            .getElementById(this.dialogContentSelectors.buyNow)
            ?.querySelector('.xpl-gh-dialog-body') as HTMLElement;
          const today = new Date(Date.now());

          const initialCta = {
            header: header?.textContent,
            body: body?.innerHTML,
            btn: btn.textContent,
          };

          const evtData = {
            accountName: this.user?.currentAccount.name,
            accountId: this.user?.currentAccount.id,
            email: this.user?.email,
            dateOfRequest: today.toDateString(),
          };

          this.intercomService.Intercom(
            'trackEvent',
            `User Subscription Requested: ${
              btn.closest<HTMLDivElement>(
                `#${this.dialogContentSelectors.buyNow}`,
              )?.dataset.ghServiceName
            }`,
            evtData,
          );

          const btnWrap = btn.closest('.xpl-button--default');
          btnWrap?.classList.add('xpl-button--success');
          btnWrap?.classList.remove('xpl-button--warning');

          header.innerText = 'Your request has been submitted';
          body.innerHTML =
            '<p>We&apos;ll be in touch once your account(s) have been granted access. In the meantime, <a href="https://docs.brandbot.com/en/collections/" target="_blank" rel="nofollow">check out our knowledge base videos and articles to get a head start!</a></p>';
          btn.innerText = "Request Sent! We'll be in touch";

          setTimeout(() => {
            btnWrap?.classList.remove('xpl-button--success');
            btnWrap?.classList.add('xpl-button--warning');

            btn.setAttribute('disabled', '');
          }, 5000);

          const replaceModalContentWInit = () => {
            btn.removeAttribute('disabled');

            header.innerText = initialCta.header as string;
            body.innerHTML = initialCta.body;
            btn.innerText = initialCta.btn as string;
          };
          this.dialog?.on('hide', () => {
            replaceModalContentWInit();

            this.dialog?.off('hide', replaceModalContentWInit);
          });
        });

      document
        .getElementById('dialog__go-back_button')
        ?.addEventListener('click', (e) => {
          e.preventDefault();

          history.back();
        });
    });

    this.setMarkup(shadow, isFixed !== 'false');
    this.setStyling(shadow);
    await this.handleLogin(shadow);
    this.listenForMessages(shadow);

    shadow
      .getElementById('main-nav')
      ?.addEventListener<'click'>('click', (evt) => {
        const clickedNode = evt.target as HTMLButtonElement;
        if (clickedNode.dataset.ghServiceIsSubscribed === 'false') {
          evt.preventDefault();

          this.showBuyNowModal(clickedNode, shadow);
        }

        const [linkElm, activeService] =
          this.activeStateService.getClickedService(evt, this.services);

        if (linkElm && activeService) {
          this.services = this.activeStateService.setActiveService(
            this.services,
            activeService.url,
          );

          this.activeStateService.setActiveNavItems(linkElm, activeService);
        }
      });
  }

  checkIfShowBuyModal(shadow: ShadowRoot) {
    Array.from(
      shadow.querySelectorAll<HTMLButtonElement>('[data-gh-service-buy-link]'),
    ).forEach((buttonNode) => {
      if (
        (buttonNode.dataset.ghServiceBuyLink || '').includes(
          window.location.origin,
        ) &&
        buttonNode.dataset.ghServiceBuyLink !== window.location.href &&
        buttonNode.dataset.ghServiceIsSubscribed !== 'true'
      ) {
        this.showBuyNowModal(buttonNode, shadow);
      }
    });
  }

  async setActiveAccount(
    shadow: ShadowRoot,
    accountObj: { id: number; name: string; isSuper: boolean },
    shouldSwitchAccount = true,
  ) {
    const { id, name } = accountObj;

    try {
      if (shouldSwitchAccount) {
        await this.fetch<BBUser>('/users/switch-membership', {
          method: 'POST',
          body: JSON.stringify({
            account_id: id,
          }),
        });
      }

      this.dialog?.hide();

      const currentAccountDisplay = shadow.getElementById(
        this.currentAccountDisplaySelector,
      );

      if (currentAccountDisplay && name) {
        currentAccountDisplay.innerText = name;
      }

      const {
        data: {
          current_account: updatedCurrentAccount,
          email: updatedEmail,
          fullname: updatedFullName,
          user_hash: updatedUserHash,
          uuid: updatedUuid,
          is_super: isSuper,
        },
      } = await this.fetch<BBUser>('/user');

      this.user = {
        currentAccount: {
          name: updatedCurrentAccount.name,
          id: updatedCurrentAccount.id,
        },
        email: updatedEmail,
        fullName: updatedFullName,
        isSuper,
        uuid: updatedUuid,
        userHash: updatedUserHash,
      };

      if (!isSuper) {
        document
          .getElementById(this.dialogSearchFormSelector)
          ?.querySelector('#search-by-id')
          ?.closest('.xpl-input')
          ?.remove();
      }

      if (this.user?.currentAccount.name !== currentAccountDisplay?.innerText) {
        if (currentAccountDisplay) {
          currentAccountDisplay.innerText =
            this.user?.currentAccount.name || '';
        }
      }

      window.XplGrowthHub.currentUser = this.user;

      this.buildNavigation(shadow);

      if (shouldSwitchAccount) {
        triggerEvent<typeof window.XplGrowthHub.currentUser>(
          xplEvts.afterAccountSelected,
          {
            detail: window.XplGrowthHub.currentUser,
          },
        );
      }
    } catch (e) {
      // TODO: Add better error logging logic
      // eslint-disable-next-line no-console
      console.error(e);
    }
  }

  checkIsAuthenticated() {
    const authCookie = getCookie(this.AUTH_SESSION_NAME);

    return !!authCookie;
  }

  setBuyNowCta(cta: keyof typeof this.ctaContentMap = 'default') {
    const buyNowElement = document.getElementById(
      this.dialogContentSelectors.buyNow,
    );
    const buyNowLinkElement = document.getElementById(
      this.dialogContentSelectors.buyNowLink,
    );

    if (buyNowElement) {
      const headerElement = buyNowElement.querySelector(
        '.xpl-gh-dialog-header',
      );
      const bodyElement = buyNowElement.querySelector('.xpl-gh-dialog-body');

      if (headerElement) {
        headerElement.innerHTML = this.ctaContentMap[cta].title;
      }

      if (bodyElement) {
        bodyElement.innerHTML = this.ctaContentMap[cta].body;
      }
    }

    if (buyNowLinkElement) {
      buyNowLinkElement.innerHTML = this.ctaContentMap[cta].ctaButton;
    }
  }

  createGHDialog() {
    const dialogContainer = document.createElement('div');
    dialogContainer.classList.add('xpl-gh-dialog-container');
    dialogContainer.setAttribute('id', this.dialogSelector);
    dialogContainer.setAttribute('aria-hidden', 'true');
    dialogContainer.setAttribute('data-a11y-dialog', 'select-account');
    dialogContainer.innerHTML = `
      <div class="xpl-gh-dialog-overlay" data-a11y-dialog-hide></div>
      <div class="xpl-gh-dialog-content" role="document">
        <button
          data-a11y-dialog-hide
          class="xpl-gh-dialog-close"
          aria-label="Close this dialog window"
        >
          ${classBtnSvg}
        </button>

        <div class="xpl-gh-dialog-content-wrapper">
          <div class="xpl-gh-dialog-content-group" id="${this.dialogContentSelectors.accountSwitcher}" aria-hidden="true">
            <header class="xpl-gh-dialog-header">
              <h1 class="xpl-gh-text-title-3">Switch Account</h1>
              <form id="${this.dialogSearchFormSelector}">
                <div class="form-body">
                  <div class="xpl-input">
                    <div class="xpl-input-wrapper">
                        <input id="search-by-name" name="search-by-name" placeholder="Search by Name" type="text" />
                    </div>
                  </div>
                  <div class="xpl-input">
                    <div class="xpl-input-wrapper">
                        <input id="search-by-id" name="search-by-id" placeholder="Search by Id" type="text" />
                    </div>
                  </div>
                </div>
              </form>
            </header>
            <div class="xpl-gh-dialog-body"></div>
          </div>
          <div class="xpl-gh-dialog-content-group" id="${this.dialogContentSelectors.buyNow}" aria-hidden="true">
            <header class="xpl-gh-dialog-header"></header>
            <div class="xpl-gh-dialog-body"></div>
            <div class="xpl-gh-dialog-footer xpl-button-row">
              <div class="xpl-button-row-inner">
                <div id="dialog__go-back_button" class="xpl-button xpl-button--default xpl-button--secondary">
                  <button>Go Back</button>
                </div>
                <div id="${this.dialogContentSelectors.buyNowLink}" class="xpl-button xpl-button--default xpl-button--warning xpl-button--primary"></div>
              </div>
            </div>
          </div>
        </div>
      </div>
    `;

    document
      ?.querySelector('[slot="main-content"]')
      ?.appendChild(dialogContainer);
  }

  setMarkup(shadow: ShadowRoot, isFixed = true) {
    shadow.innerHTML = `
      <header${isFixed !== false ? ' class="is--fixed"' : ''}>
        <nav id="main-nav">
          <a href="${this.growthHubHomeUrl}" style="display: block;">
            <figure id="main-logo">
              ${growthHubSvg}
            </figure>
          </a>
          <ul></ul>
        </nav>
        <nav id="account-details"></nav>
      </header>
      <section class="main-content__container">
        <slot name="main-content"></slot>
      </section>
    `;

    this.createGHDialog();

    window?.XplGrowthHub?.setIsActive();
  }

  setStyling(shadow: ShadowRoot) {
    const apolloStyleElement = document.createElement('style');
    apolloStyleElement.textContent = apolloCss;
    shadow.appendChild(apolloStyleElement);

    const growthHubStyleElement = document.createElement('style');
    growthHubStyleElement.textContent = growthHubCss;
    shadow.appendChild(growthHubStyleElement);

    const dialogStyleElement = document.createElement('style');
    dialogStyleElement.textContent = growthHubModalCss;
    document.head.appendChild(dialogStyleElement);
  }

  async handleLogin(shadow: ShadowRoot, isAuthRetry = false) {
    const curUrl = new URL(window.location.href);
    let ghQpStr = curUrl.searchParams.get(this.GROWTH_HUB_QUERY_PARAM_KEY);
    const ghQp = ghQpStr ? parseGhQp(ghQpStr) : null;

    if (isAuthRetry) {
      removeCookie(this.AUTH_SESSION_NAME);
      curUrl.searchParams.delete(this.GROWTH_HUB_QUERY_PARAM_KEY);
      window.history.replaceState(curUrl.toString(), document.title);
      ghQpStr = null;
    }

    const isAuthenticated = this.checkIsAuthenticated();

    if (isAuthenticated) {
      triggerEvent(xplEvts.afterAuthComplete);
      return Promise.resolve();
    }

    if (!isAuthenticated && ghQpStr) {
      const { code } = ghQp as GrowthHubQueryParam;

      invariant(code, 'No code parameter was found');

      const iframeContainer = document.createElement('div');
      iframeContainer.classList.add(this.iframeClassKey);

      const iframe = document.createElement('iframe');
      const iframeUrl = new URL(`${this.growthHubBaseUrl}/iframe-callback`);

      iframeUrl.searchParams.append('code', code);

      const codeVerifierCookie = getCookie(this.AUTH_CODE_VERIFIER_COOKIE_NAME);

      invariant(
        codeVerifierCookie,
        'No code verifier cookie parameter was found',
      );

      iframeUrl.searchParams.append('code_verifier', codeVerifierCookie);

      [
        ['allowtransparency', 'true'],
        ['title', 'xpl_gh_portal'],
        ['src', iframeUrl.toString()],
      ].forEach(([attr, value]) => {
        iframe.setAttribute(attr, value);
      });

      iframe.style.backgroundColor = 'transparent';
      iframe.style.border = 'none';
      iframeContainer.appendChild(iframe);
      shadow.appendChild(iframeContainer);

      return Promise.resolve();
    }

    const { code_challenge: codeChallenge, code_verifier: codeVerifier } =
      pkceChallenge(128);

    setCookie(this.AUTH_CODE_VERIFIER_COOKIE_NAME, codeVerifier, {
      expires: 1,
    });

    const params = new URLSearchParams({
      code_challenge: codeChallenge,
      redirect_url: window.location.href,
    });

    const url = new URL(
      `${this.growthHubBaseUrl}/connector?${params.toString()}`,
    );

    window.location.assign(url.toString());

    return Promise.resolve();
  }

  async getServices(accountId: number): Promise<ServiceLinkItem[] | void> {
    try {
      const servicesArr = await this.fetch<Service[]>(
        `/services/${accountId}`,
        {},
        `${this.growthHubBaseUrl}/api/v1`,
      );

      return servicesArr?.filter(
        ({ navItemType }) => navItemType !== 'none',
      ) as ServiceLinkItem[];
    } catch (e) {
      // TODO: Add better error logging logic
      // eslint-disable-next-line no-console
      console.error(e);
    }
  }

  async fetch<T = undefined>(
    endpoint: string,
    config?: RequestInit,
    rootUrl = this.brandbotApiUrl,
  ): Promise<T> {
    const isAuthenticated = this.checkIsAuthenticated();

    try {
      const fetchConfig = { ...config };

      if (isAuthenticated) {
        fetchConfig.headers = {
          ...(fetchConfig.headers || {}),
          Authorization: `Bearer ${getCookie(this.AUTH_SESSION_NAME)}`,
          Accept: 'application/json',
          'Content-Type': 'application/json',
        };
      }
      const res = await this.fetchRetry(`${rootUrl}${endpoint}`, fetchConfig);

      if (res.ok) return res.json() as T;

      return new Error(res.statusText) as T;
    } catch (err) {
      // TODO: replace with logger logic
      // eslint-disable-next-line no-console
      console.error('Fetch Failed:', err);

      throw err as T;
    }
  }

  buildMainNav(servicesArr: ServiceLinkItem[]) {
    return [
      ...new Set(
        servicesArr
          .filter((service) => service.navItemType === 'main_nav')
          .map((service) => service.category),
      ),
    ].reduce(
      (acc, category) => {
        const services = servicesArr.filter(
          (service) => service.category === category,
        );

        return [
          ...acc,
          {
            category,
            services,
          },
        ];
      },
      [] as {
        category: string;
        services: ServiceLinkItem[];
      }[],
    );
  }

  buildDDNav(servicesArr: Service[]) {
    return [
      ...new Set(
        servicesArr
          .filter(({ navItemType }) => navItemType === 'settings_nav')
          .map(({ name: text, url: link }) => ({
            text,
            link,
          })),
      ),
    ];
  }

  buildAccountPreloader(shadow: ShadowRoot) {
    const accountDetailsContainer = shadow.getElementById(
      this.accountDeetsContainerSelector,
    );
    const accountDetailsPreloader = document.createElement('div');
    accountDetailsPreloader?.classList.add('xpl-select-loader');
    accountDetailsPreloader.innerHTML = `
        <div class="load-wrapper">
          <div class="activity"></div>
        </div>
    `;

    accountDetailsContainer?.appendChild(accountDetailsPreloader);
  }

  buildCurrentAccountDisplay(
    shadow: ShadowRoot,
    currentAccount: CurrentUser['currentAccount'],
  ) {
    const accountDetailsContainer = shadow.getElementById(
      this.accountDeetsContainerSelector,
    );
    const currentAccountDisplay = document.createElement('span');
    currentAccountDisplay.setAttribute(
      'id',
      this.currentAccountDisplaySelector,
    );
    currentAccountDisplay.classList.add('xpl-gh-text-title-5');
    currentAccountDisplay.innerText = currentAccount.name;
    accountDetailsContainer?.append(currentAccountDisplay);
  }

  buildCurrentUserAvi(
    shadow: ShadowRoot,
    fullName: BBUser['data']['fullname'],
  ) {
    const accountDetailsContainer = shadow.getElementById(
      this.accountDeetsContainerSelector,
    );
    const accountAvi = document.createElement('div');
    accountAvi.setAttribute('iconOnly', 'true');
    accountAvi.classList.add(
      'user-avatar',
      'xpl-button',
      'xpl-button--default',
      'xpl-button--primary',
    );

    accountAvi.innerHTML = `
      <button>
        ${
          fullName
            ? fullName
                .split(' ')
                .reduce((acc, [firstLetter]) => `${acc}${firstLetter}`, '')
            : `<xpl-icon size={24} icon="gear"></xpl-icon>`
        }
      </button>
    `;

    accountAvi?.querySelector('button')?.addEventListener('click', () => {
      shadow
        .querySelector('.xpl-dropdown')
        ?.classList.toggle(this.ddOpenSelector);
    });

    accountDetailsContainer?.append(accountAvi);

    document.addEventListener('click', (e) => {
      const clickedElementArr = e.composedPath();

      if (!clickedElementArr.includes(accountAvi)) {
        const ddElm = document
          ?.querySelector('growth-hub')
          ?.shadowRoot?.querySelector('.xpl-dropdown');
        const isDDOpen = ddElm?.classList.contains('xpl-dropdown--open');

        if (isDDOpen) ddElm?.classList.remove('xpl-dropdown--open');
      }
    });
  }

  buildAccountDropdown(shadow: ShadowRoot) {
    const accountDetailsContainer = shadow.getElementById(
      this.accountDeetsContainerSelector,
    );
    const accountDropdownContainer = document.createElement('div');
    accountDropdownContainer.classList.add('dropdown-container', 'dark');

    const accountDropdown = document.createElement('nav');
    accountDropdown.classList.add('xpl-dropdown');
    const staticDDOptions = [
      { text: 'Select Account', link: null },
      { text: 'Help Suite', link: 'https://docs.brandbot.com/en/' },
      { text: 'Logout', link: null },
    ];
    const ddOptions = [
      ...this.buildDDNav(this.services || []),
      ...staticDDOptions,
    ];

    accountDropdown.innerHTML = `<ul class="xpl-dropdown-list">${ddOptions.reduce(
      (acc, { text, link }) => `
        ${acc}
        <li
          class="xpl-dropdown-option xpl-dropdown-list-item"
          role="option"
          data-option-value="${text.toLowerCase().replace(' ', '-')}"
        >
          ${link ? `<a href="${link}" target="_blank" rel="nofollow">${text}</a>` : text}
        </li>
      `,
      '',
    )}</ul>`;

    accountDetailsContainer?.append(accountDropdownContainer);
    accountDropdownContainer.appendChild(accountDropdown);

    accountDropdown?.addEventListener('click', (e) => {
      const item = e.target as HTMLLIElement;
      const value = item?.dataset.optionValue;

      if (value) {
        switch (value) {
          case 'select-account':
            this.displayModalContent(
              this.dialogContentSelectors.accountSwitcher,
              item,
            );
            this.dialog?.show();
            accountDropdown.classList.remove(this.ddOpenSelector);
            return;
          case 'logout':
            this.logout();
            return;
          default:
            // TODO: replace with logger logic
            // eslint-disable-next-line no-console
            console.error(`${value} is not a valid option.`);
            break;
        }
      }
    });
  }

  removeAccountPreloader(shadow: ShadowRoot) {
    const accountPreloader = shadow.querySelector(
      '.xpl-select-loader',
    ) as Element;
    const accountDetailsContainer = shadow.getElementById(
      this.accountDeetsContainerSelector,
    );
    accountDetailsContainer?.classList.add('overflow-hidden');
    accountDetailsContainer?.removeChild(accountPreloader);
  }

  setAccountOptions(
    accounts: BBMemberships['memberships'],
    selectedAccountId?: number,
    isSuper = false,
  ) {
    const accountSelector = document.getElementById(
      this.accountPickerId,
    ) as HTMLUListElement;

    accountSelector.innerHTML = (accounts || [])
      ?.sort((a, b) => a.id - b.id)
      .reduce(generateAccountListReducer(selectedAccountId, isSuper), '');
  }

  buildAccountModal(
    shadow: ShadowRoot,
    accounts: BBMemberships['memberships'],
    selectedAccountId?: number,
    isSuperUser = false,
  ) {
    const modalContainer = document.getElementById(
      this.dialogSelector,
    ) as HTMLElement;
    this.dialog = new A11yDialog(modalContainer);
    const accountSelector = document.createElement('ul');
    accountSelector.setAttribute('id', this.accountPickerId);
    accountSelector.classList.add('xpl-list');
    modalContainer
      ?.querySelector('.xpl-gh-dialog-body')
      ?.appendChild(accountSelector);

    this.setAccountOptions(accounts, selectedAccountId, isSuperUser);

    accountSelector.addEventListener('click', async (e) => {
      const evt = e as PointerEvent;
      const target = evt.target as HTMLElement;

      if (target?.dataset?.ghAccountOption === 'true') {
        const prevSelectedItem = accountSelector.querySelector('li.selected');

        const nextSelectedItem = (e.target as HTMLButtonElement).closest('li');

        prevSelectedItem?.classList.remove('selected');
        nextSelectedItem?.classList.add('selected');
        try {
          const { ghAccountOptionName: name, ghAccountOptionId: id } = (
            evt.target as HTMLButtonElement
          )?.dataset;

          await this.setAccount(shadow, {
            name: name as string,
            id: Number(id),
            isSuper: isSuperUser,
          });
        } catch (err) {
          // TODO: replace with logger logic
          // eslint-disable-next-line no-console
          console.error(err);

          prevSelectedItem?.classList.add('selected');
          nextSelectedItem?.classList.remove('selected');
        }
      }
    });

    this.dialog.on('hide', () => {
      const ghDialogSearch = document.getElementById(
        this.dialogSearchFormSelector,
      ) as HTMLFormElement;

      const searchFields = ghDialogSearch.querySelectorAll('input');
      Array.from(searchFields).forEach((input: HTMLInputElement) => {
        input.value = '';
        input.removeAttribute('disabled');
        input.closest('.xpl-input')?.classList.remove('xpl-input--disabled');
      });
    });
  }

  handleAccountsSearch(accounts: BBMemberships['memberships']) {
    const ghDialogSearch = document.getElementById(
      this.dialogSearchFormSelector,
    ) as HTMLFormElement;

    ghDialogSearch.addEventListener('keyup', (evt) => {
      const { name, type, value } = evt.target as HTMLInputElement;
      const searchFieldPrefix = 'search-by-';

      if (type === 'text' && name?.includes(searchFieldPrefix)) {
        const searchType = name?.replace(searchFieldPrefix, '');
        const theOtherSearch = ghDialogSearch
          .querySelector(
            `#${searchFieldPrefix}${searchType === 'name' ? 'id' : 'name'}`,
          )
          ?.closest('.xpl-input');

        if (value.length > 0) {
          this.setAccountOptions(
            accounts.filter(({ name: accountName, id: accountId }) => {
              theOtherSearch?.classList.add('xpl-input--disabled');
              theOtherSearch
                ?.querySelector('input')
                ?.setAttribute('disabled', '');

              const ignoredCharsRegex = /[\^*(),.":{}|<>_\-\[\]\\\/`~+=\s]/g;
              if (searchType === 'name') {
                return accountName
                  .toLowerCase()
                  .replace(ignoredCharsRegex, '')
                  .includes(value.toLowerCase().replace(ignoredCharsRegex, ''));
              }
              if (searchType === 'id') {
                return accountId.toString().includes(value);
              }
            }),
            this.user?.currentAccount.id,
            this.user?.isSuper,
          );
        } else {
          theOtherSearch?.classList.remove('xpl-input--disabled');
          theOtherSearch?.querySelector('input')?.removeAttribute('disabled');
          this.setAccountOptions(
            accounts,
            this.user?.currentAccount.id,
            this.user?.isSuper,
          );
        }
      }
    });
  }

  buildNavigation(shadow: ShadowRoot) {
    const navItemsContainer = shadow.querySelector('#main-nav ul');

    if (navItemsContainer) {
      const navItems = this.buildMainNav(this.services || [])
        .map(({ services, ...props }) => ({
          ...props,
          services,
          isActive: !services.every(({ isActive }) => isActive === false),
        }))
        .reduce(
          (acc: HTMLLIElement[], serviceGroups) => [
            ...acc,
            ...createMenuItem(serviceGroups),
          ],
          [],
        );

      navItemsContainer.replaceChildren(...navItems);
    }
  }

  async setAccount(
    shadow: ShadowRoot,
    {
      id: initialAccountId,
      isSuper: initialIsSuper,
      name: initialAccountName,
    }: { id: number | null; isSuper: boolean; name: string } = {
      id: null,
      name: '',
      isSuper: false,
    },
  ) {
    try {
      await this.setActiveAccount(
        shadow,
        {
          id: Number(initialAccountId),
          isSuper: initialIsSuper,
          name: initialAccountName,
        },
        initialAccountId !== null,
      );

      this.services =
        (await this.getServices(this.user?.currentAccount?.id)) || [];

      this.services = this.activeStateService.setActiveService(
        this.services,
        window.location.href,
      );

      const { memberships: accounts } =
        await this.fetch<BBMemberships>('/users/memberships');

      this.accounts = accounts || [];
    } catch (err) {
      // TODO: replace with logger logic
      // eslint-disable-next-line no-console
      console.error(err);
    }
  }

  listenForMessages(shadow: ShadowRoot) {
    window.addEventListener('message', (evt) => {
      if (
        [this.txMsgHref, this.growthHubBaseUrl].includes(evt.origin) &&
        (evt.data as TemplateVars['iframeMsg']).source === 'growth-hub-msgs'
      ) {
        const msg = parsePostMsg(evt.data as string);

        if (msg?.accessToken) {
          const today = new Date(Date.now());
          const nextMonth = new Date(today);
          nextMonth.setDate(nextMonth.getDate() + 30);
          setCookie(this.AUTH_SESSION_NAME, msg?.accessToken, {
            expires: nextMonth,
          });
        }

        const iframeContainer = shadow.querySelector(`.${this.iframeClassKey}`);

        if (iframeContainer) shadow.removeChild(iframeContainer);
        triggerEvent(xplEvts.afterAuthComplete);
      }
    });
  }

  logout() {
    removeCookie(this.AUTH_SESSION_NAME);
    triggerEvent(xplEvts.afterLogoutComplete);
  }
}
